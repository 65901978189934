<template>
    <section id="page-title" class="page-title-parallax page-title-dark swiper-slide- full-screen-" style="padding: 50px 0; background-image: url('https://erasg.s3.ap-southeast-1.amazonaws.com/uat/301-calendar.jpg'); background-size: cover;"> 
        <div class="slider-caption-inner-trainer center slider-caption-center"> 
            <h2 data-animate="fadeInUp"></h2> 
        </div>				 
    </section>

    <section id="content"> 
        <div class="content-wrap nopadding">
            <div class="section notopmargin nobottommargin"> 
                <div class="container clearfix"> 
                   <div class="row">
                        <div class="col-md-12 col-lg-6">
                            <form @submit.prevent="submit">
                                <h1>Contact Us</h1>
                                <h4>ERA Singapore</h4>
                                <div class="row">
                                    <div class="col-sm-12 col-md-6">
                                        <div class="mb-2"><i class="fa-solid fa-location-dot"></i> Address:<br>
                                            ERA APAC Centre<br>
                                            450 Lorong 6 Toa Payoh<br>
                                            Singapore 319394
                                        </div>
                                        <div><i class="fa-solid fa-phone"></i> Phone: +65 6226 2000</div>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <div class="mb-2"><i class="fa-solid fa-clock"></i> Operating Hours:<br>
                                            Monday – Friday<br>
                                            9:00 AM – 6:30 PM
                                        </div>
                                        <div><i class="fa-solid fa-envelope"></i> Email: era@era.com.sg</div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <p>We would love to hear from you.</p>

                                    <div class="form-group row">
                                        <div class="col-md-12">
                                            <div class="form-floating">
                                                <input type="text" placeholder="name" class="form-control" v-model="form.name" :class="{ 'is-invalid': error.name}">
                                                <div class="invalid-feedback">{{ error.name }}</div>
                                                <label for="floatingInput">Name</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <div class="form-floating">
                                                <input type="email" placeholder="Email" class="form-control" v-model="form.email" :class="{ 'is-invalid': error.email}">
                                                <div class="invalid-feedback">{{ error.email }}</div>
                                                <label for="floatingInput">Email</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-floating">
                                                <input type="text" placeholder="Phone" class="form-control"  v-model="form.phone" :class="{ 'is-invalid': error.phone}">
                                                <div class="invalid-feedback">{{ error.phone }}</div>
                                                <label for="floatingInput">Phone</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-md-12">
                                            <div class="form-floating">
                                                <textarea class="form-control" placeholder="Your message" rows="6" style="height: 100px" v-model="form.message" :class="{ 'is-invalid': error.message}"></textarea>
                                                <div class="invalid-feedback">{{ error.message }}</div>
                                                <label for="floatingInput">Your message</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-md-12">
                                            <button type="submit" class="btn btn-outline-primary era-btn">
                                                <i class="fa-solid fa-paper-plane"></i> Send Message
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                        <div class="col-md-12 col-lg-6">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15954.973945374599!2d103.847154!3d1.329969!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2ec370eb7c98c633!2sERA%20Realty%20Network%20Pte%20Ltd!5e0!3m2!1sen!2sus!4v1659928707826!5m2!1sen!2sus" width="600" height="625" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                   </div>
                </div>
            </div>
        </div>
    </section>

</template>
<script>
import { ref, inject, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from "vue-router";
import axios from 'axios';
import { store } from '../../store.js';

export default{
    setup() {
        const app = getCurrentInstance();
        const router = useRouter();
        const route = useRoute();
        const swal = inject('$swal');
        const form = ref([]);
        const error = ref([]);

        return { app, router, route, swal, form, error };
    },
    methods: {
        submit() {
            this.error.form = false;
            this.error = [];

            if (!this.form.name) {
                this.error.form = true;
                this.error.name = 'Name is required';
            }

            if (!this.form.email) {
                this.error.form = true;
                this.error.email = 'Email is required';
            }

            if (!this.form.message) {
                this.error.form = true;
                this.error.message = 'Your message is required';
            }

            if (!this.error.form) {
                this.send();
            }
        },
        async send() {
            store.loading = true;
            let model = this;
            await axios
            .post(process.env.VUE_APP_ENDPOINT+'/contact',{
                name: this.form.name,
                email: this.form.email,
                phone: this.form.phone,
                message: this.form.message
            })
            .then(function (res) {
                store.loading = false;
                model.form = [];
                model.swal({
                    icon: 'success',
                    text: res.data.message,
                    showCloseButton: true,
                    showConfirmButton: false
                });
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status != 500) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }
    }
}
</script>