<template>
    <h2 class="si-share">{{ rank.name }}</h2>
    <div class="justify-content-center row bottommargin"> 
        <div class="col-lg-3 col-md-4 col-sm-6" v-for="(trainer, index) in rank.trainers" :key="index"> 
            <div class="ipost clearfix"> 
                <div class="entry-image">
                    <a :href="trainer.url">
                        <img class="image_fade" :src="trainer.thumbnail" :alt="trainer.name+' - '+ rank.name">
                    </a>
                </div>										 
                <div class="entry-title"> 
                    <h2>{{ trainer.name }}</h2> 
                </div>										 
                <div class="entry-content">
                    <a :href="trainer.url" class="col_full button button-red button-rounded button-small noleftmargin topmargin-sm">
                        Learn more
                    </a>
                </div>										 
            </div>									 
        </div>
    </div>
</template>
<script>
export default {
    props: ['rank'],
}
</script>