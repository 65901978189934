<template>
    <div>
    <section id="page-title" class="page-title-parallax page-title-dark swiper-slide full-screen" 
        style="padding: 50px 0; background-size: cover;"
        :style="'background-image: url(' + post.cover + ');'" v-if="post.cover"> 
            <div class="slider-caption-inner-trainer center slider-caption-center">
                <h4 v-if="post.course_code">{{ post.course_code }}</h4>
                <h2 data-animate="fadeInUp">{{ post.title }}</h2> 
                <div class="box-lines-trans divcenter" v-if="post.logos">
                    <img class="theme-logo-padding" :src="logo.full" v-for="(logo, index) in post.logos" :key="index" :alt="post.title"/>
                </div>			 
                <a href="#" data-scrollto="#content" data-offset="100" class="dark one-page-arrow"><i class="icon-angle-down infinite animated fadeInDown"></i></a> 
            </div>				 
    </section>

    <div id="page-menu" class="sticky-page-menu"> 
        <div id="page-menu-wrap"> 
            <div class="container clearfix"> 
                <div class="menu-title dark"> 
                    <nav class="one-page-menu"> 
                        <ul> 
                            <li> 
                                <a href="#"><div>{{ parent.title }}</div></a> 
                            </li>									 
                        </ul>								 
                    </nav>							 
                </div>
                <nav class="one-page-menu"> 
                    <ul> 
                        <li v-for="(sub, index) in subs" :key="index" :class="{ 'current': sub.slug == post.slug }"> 
                            <a :href="sub.slug"><div>{{ sub.course_code }}</div></a> 
                        </li>
                    </ul>							 
                </nav>						 
                <div id="page-submenu-trigger"> 
                    <i class="icon-reorder"></i> 
                </div>						 
            </div>					 
        </div>				 
    </div>

    <section id="content"> 
        <div class="content-wrap nopadding"> 
            <div class="container clearfix"> 
                <div class="heading-block divcenter center col_four_fifth topmargin bottommargin nobottomborder">
                    <div v-html="post.content"></div>
                </div>
            </div>
            <CourseVideo :post="post"/>

            <CourseKeypoint :post="post"/>

            <TrainerProfile :trainer="trainer" v-for="(trainer, index) in post.trainers" :key="index"/>

            <div class="masonry-thumbs grid-3 topmargin" data-big="12" data-lightbox="gallery" style="position: relative;"> 
                <a :href="image.full" data-lightbox="gallery-item" v-for="(image, index) in post.gallery" :key="index">
                    <img class="image_fade" :src="image.full">
                </a> 
            </div>

            <div style="background-color: #282828;">&nbsp;</div>

            
            <CourseTestimonial :post="post" v-if="post.testimonials"/>
            <RsvpFooter/>

        </div> 
    </section>
    </div>
</template>

<script>
import axios from 'axios';
import { ref, onMounted, inject, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from "vue-router";
import RsvpFooter from '../components/RsvpFooter.vue';
import CourseTestimonial from '../components/CourseTestimonial.vue';
import TrainerProfile from '../components/TrainerProfile.vue';
import CourseKeypoint from '../components/CourseKeypoint.vue';
import CourseVideo from '../components/CourseVideo.vue';
import { store } from '../store.js';

export default {
    components: {
        RsvpFooter,
        CourseTestimonial,
        TrainerProfile,
        CourseKeypoint,
        CourseVideo
    },
    setup() {
        const app = getCurrentInstance();
        const router = useRouter();
        const route = useRoute();
        const swal = inject('$swal');
        const post = ref([]);
        const parent = ref([]);
        const subs = ref([]);

        async function getPost() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/post/'+route.params.slug)
            .then(function (res) {
                store.loading = false;
                post.value = res.data.post;
                parent.value = res.data.post.parent;
                subs.value = res.data.post.parent.subs;
                document.title = res.data.post.title + ' | ' + process.env.VUE_APP_NAME;
                //document.querySelector('meta[name="description"]').setAttribute("content",res.data.post.meta.description);
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 404) {
                   window.location.href = '/404';
                } else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }
        
        onMounted(() => {
            getPost();
        });

        return { router, swal, post, parent, store, subs };
    },
    methods: {
         
    }
}
</script>