<template>
    
    <div class="notopmargin nobottommargin notopborder center" v-if="loading"> 
        <div class="container clearfix placeholder-glow">
            <div class="justify-content-center row bottommargin">
                <div class="col-lg-4 col-md-4 col-sm-4 mb-4">
                   <div class="placeholder col-12 rounded" style="height:200px"></div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                   <div class="placeholder col-12 rounded" style="height:200px"></div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                   <div class="placeholder col-12 rounded" style="height:200px"></div>
                </div>
                 <div class="col-lg-4 col-md-4 col-sm-4">
                   <div class="placeholder col-12 rounded" style="height:200px"></div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                   <div class="placeholder col-12 rounded" style="height:200px"></div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                   <div class="placeholder col-12 rounded" style="height:200px"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="notopmargin nobottommargin notopborder center"> 
        <div class="container clearfix">
            <div class="justify-content-center row bottommargin">
                <div class="col-lg-4 col-md-4 col-sm-4" v-for="(video, index) in videos" :key="index"> 
                    <div class="ipost bottommargin-sm clearfix"> 
                        <div class="entry-image"> 
                            <div class="fluid-width-video-wrapper" style="padding-top: 50%;"><iframe :src="'https://www.youtube.com/embed/' + video.video_id" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" id="fitvid0"></iframe></div>
                        </div>										 
                        <div class="entry-title"> 
                            <h3>{{ video.title }}</h3> 
                        </div>										 
                    </div>									 
                </div>
            </div>
        </div>						 
    </div>
</template>
<script>
import { store } from '../store.js';
import { ref, inject, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from "vue-router";
import axios from 'axios';

export default {
    props: ['post'],
    setup() {
        const app = getCurrentInstance();
        const router = useRouter();
        const route = useRoute();
        const swal = inject('$swal');
        const loading = ref(false);
        const videos = ref(false);

        return { app, swal, route, router, store, loading, videos };
    },
    watch:{ 
        post() {
            if (this.post.youtube) {
                this.getPostVideo();
            }
        }
    },
    methods: {
        async getPostVideo() {
            this.loading = true;
            let model = this;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/post/videos/'+this.post.slug)
            .then(function (res) {
                model.loading = false;
                model.videos = res.data.items;
            })
            .catch(function (error) {
                model.loading = false;
                if (error.response && error.response.status == 401) {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }
    }
}
</script>