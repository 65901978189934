<template>
    <div class="container clearfix bottommargin">
        <div class="bottommargin center col_four_fifth divcenter heading-block nobottomborder- topmargin"> 
            <h2><strong>Question &amp; Answer</strong></h2> 
        </div>						 
        <div class="accordion accordion-bg clearfix"> 
            <template v-for="(qa, index) in trainer.qas" :key="index">
                <div class="acctitle"> 
                    <i class="acc-closed icon-question-circle1"></i> 
                    <i class="acc-open icon-remove-circle"></i>{{qa.question}} 
                </div>							 
                <div class="acc_content clearfix" style="display: none;"> 
                    <i class="icon-chat-3 si-borderless social-icon"></i><div v-html="qa.answer"></div>
                </div>
            </template>
        </div>						 
    </div>
</template>
<script>
export default {
    props: ['trainer'],
    watch:{ 
        trainer() {
            
        }
    }
}
</script>