<template>
    <div></div>
</template>

<script>
import { onMounted } from 'vue';
import axios from 'axios';
import { store } from '@/store.js';

export default {
    components: {
    },
    setup() {
        async function logout() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/auth/logout')
            .then(function () {
                store.loading = false;
                localStorage.removeItem('authToken');
                window.location.href = '/';
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 404) {
                   window.location.href = '/404';
                }
            });
        }

        onMounted(() => {
            logout();
        });

    }
}
</script>