import { createApp } from 'vue';
import './assets/css/bootstrap.css';
import './assets/style.css';
import './assets/css/custom.css';
import './assets/css/swiper.css';
import './assets/css/dark.css';
import './assets/css/font-icons.css';
import './assets/css/animate.css';
import './assets/css/magnific-popup.css';
import './assets/css/responsive.css';
import './assets/css/colors.css';
import App from './App.vue';
import router from './router';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

let app = createApp(App)
app
    .use(router)
    .use(VueSweetalert2)
    .mount('#app');
app.config.globalProperties.$error500 = 'Something went wrong. Please try again or contact the system administrator';
