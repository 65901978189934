<template>
    <div>
        <section id="page-title" class="page-title-parallax page-title-dark swiper-slide full-screen" style="padding: 50px 0px;  background-size: cover;"
        :style="'background-image: url(' + trainer.cover + ');'"> 
            <div class="slider-caption-inner-trainer center slider-caption-center">
                <a href="#" data-scrollto="#content" data-offset="100" class="dark one-page-arrow"><i class="icon-angle-down infinite animated fadeInDown"></i></a> 
            </div>				 
        </section>
        <template  v-if="trainer">
            <TrainerProfile :trainer="trainer" v-if="trainer"/>
            <QuestionAnswer :trainer="trainer" v-if="trainer.qas"/>
            <TrainerCourses :trainer="trainer" v-if="trainer.courses"/>
        </template>
    </div>
</template>

<script>
import axios from 'axios';
import { ref, onMounted, inject, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from "vue-router";
import TrainerProfile from '../components/TrainerProfile.vue';
import QuestionAnswer from '../components/QuestionAnswer.vue';
import TrainerCourses from '../components/TrainerCourses.vue';
import { store } from '@/store.js';

    export default {
        components: {
            TrainerProfile,
            QuestionAnswer,
            TrainerCourses
        },
        setup() {
            const app = getCurrentInstance();
            const router = useRouter();
            const route = useRoute();
            const swal = inject('$swal');
            const trainer = ref(false);
            const parent = ref([]);

            async function getTrainer() {
                store.loading = true;
                await axios
                .get(process.env.VUE_APP_ENDPOINT+'/trainers/'+route.params.slug)
                .then(function (res) {
                    store.loading = false;
                    trainer.value = res.data.trainer;
                    document.title = res.data.trainer.name + ' - ' + process.env.VUE_APP_NAME;
                    //document.querySelector('meta[name="description"]').setAttribute("content",res.data.trainer.description);
                })
                .catch(function (error) {
                    store.loading = false;
                    if (error.response && error.response.status == 404) {
                        window.location.href = '/404';
                    } else {
                        swal({
                            icon: 'error',
                            text: app.appContext.config.globalProperties.$error500,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    }
                });
            }
            
            onMounted(() => {
                getTrainer();
            });

            return { router, swal, parent, trainer };
        },
    }
</script>