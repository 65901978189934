<template>
    <div class="section parallax dark nobottommargin notopmargin txt-light skrollable skrollable-between" style="background-image: url('https://erasg.s3.ap-southeast-1.amazonaws.com/uat/243-testimonial-bg.jpg'); padding: 50px 0px; background-size: cover; background-position: 0px;" data-bottom-top="background-position:0px 200px;" data-top-bottom="background-position:0px -50px;"> 
            <div class="heading-block noborder bottommargin-sm center"> 
                <h3>Testimonial of Attendees</h3> 
            </div>						 
            <div class="fslider testimonial testimonial-full" data-animation="slide" data-arrows="false"> 
                <div class="flexslider">                           
                <div class="flex-viewport" style="overflow: hidden; position: relative;">
                
                    <div class="slider-wrap" style="width: 1200%; transition-duration: 0s; transform: translate3d(-1600px, 0px, 0px);">
                        <div class="slide" data-thumb-alt="" style="width: 800px; margin-right: 0px; float: left; display: block;" v-for="(testimonial, index) in post.testimonials" :key="index">								 
                            <div class="testi-content"> 
                                <p>{{ testimonial.content }}</p> 
                                <div class="testi-meta"> 
                                   {{ testimonial.author }}
                                </div>											 
                            </div>										 
                        </div>
                    </div>
                        
                </div>
                        
                
            </div>							 
        </div>
    </div>
</template>
<script>
export default {
    props: ['post'],
}
</script>