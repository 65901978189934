<template>
<div>
<div class="pag page-center">
    <div class="container container-tight py-4" style="width: 500px;">
        <div class="card card-md">
        <div class="card-body">
            <h2 class="h2 text-center mb-4">Login to your account</h2>

            <div class="hr-text hr-text-center hr-text-spaceless mb-4">myERA Login</div>

            <form class="form" @submit.prevent="submit">
                <div class="mb-3">
                    <label class="form-label">Username</label>
                    <input type="text" class="form-control" v-model="user.username" :class="{ 'is-invalid': error.username}">
                    <div class="invalid-feedback">{{ error.username }}</div>
                </div>
                <div class="mb-2">
                    <label class="form-label">
                    Password
                    <span class="form-label-description">
                        <a href="https://my.era.com.sg/" target="_blank">I forgot password</a>
                    </span>
                    </label>
                    <input type="password" class="form-control"  v-model="user.password" :class="{ 'is-invalid': error.password}">
                    <div class="invalid-feedback">{{ error.password }}</div>
                </div>
                <div class="form-footer">
                    <button type="submit" class="btn btn-primary w-100 mt-4">Sign in</button>
                </div>
            </form>

        </div><!-- card body -->
        </div><!-- card -->
    </div>
	</div>
</div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from "vue-router";
import axios from 'axios';
import { store } from '@/store.js';

export default {
    components: {
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const user = ref([]);
        const error = ref([]);

        onMounted(() => {
        });

        return { router,route, user, error, store };
    },
    methods: {
        submit() {
            this.error.form = false;
            this.clear();

            if (!this.user.username) {
                this.error.form = true;
                this.error.username = 'Username is required';
            }

            if (!this.user.password) {
                this.error.form = true;
                this.error.password = 'Password is required';
            }

            if (!this.error.form) {
                this.goSubmit();
            }

        },
        async goSubmit() {
            store.loading = true;
            let model = this;
            await axios
            .post(process.env.VUE_APP_ENDPOINT+'/auth/login',{
                username: this.user.username,
                password: this.user.password
            })
            .then(function (res) {
                store.loading = false;
                localStorage.setItem('authToken',res.data.token);
                window.location.href = '/';
            })
            .catch(function (error) {
                store.loading = false;
                model.error.username = error.response.data.message;
                model.error.password = ' ';
            });
        },
        clear() {
            this.error = [];
        }
    }
}
</script>