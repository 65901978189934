<template>
    <div class="row clearfix dark align-items-stretch bg-dark-keypoint lg-img" v-if="post.keypoint_notes"> 
        <div class="col-lg-6 center min-height-md">
            <div v-if="!post.keypoint_image">
                <div class="fluid-width-video-wrapper" style="padding-top: 56.25%;">
                    <iframe width="560" height="315" :src="'https://www.youtube.com/embed/'+post.keypoint_video" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen v-if="post.keypoint_video"></iframe>
                </div>
            </div>
            <div v-else>
                <img :src="post.keypoint_image" :alt="post.title + ' Training Takeaways'">
            </div>
        </div>						 
        <div class="col-lg-6 col-padding-md min-height-md"> 
            <div class="heading-block noborder bottommargin-sm"> 
                <h3>Training takeaways</h3> 
            </div>							 
            <div class="keypoint dark">
                <ul class="iconlist"> 
                    <li v-for="(notes, index) in post.keypoint_notes" :key="index"> 
                        <i class="icon-bolt1"></i>{{notes}}
                    </li>								 
                </ul>								 
            </div>							 
        </div>						 
    </div>
</template>
<script>
import { ref, inject, getCurrentInstance } from 'vue';
import { useRouter } from "vue-router";
import { store } from '../store.js';

export default {
    props: ['post'],
    data() {
        const app = getCurrentInstance();
        const router = useRouter();
        const swal = inject('$swal');
        const form = ref([]);
        const error = ref([]);
        const loading = ref(false);
        const images = ref(false);

        return { app, router, swal, form, error, store, images, loading }
    },
    watch: {
    },
    methods: {
    }
}
</script>