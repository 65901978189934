<template>
    <div>
    <section id="page-title" class="page-title-parallax page-title-dark swiper-slide- full-screen-" style="padding: 50px 0; background-image: url('https://erasg.s3.ap-southeast-1.amazonaws.com/uat/301-calendar.jpg'); background-size: cover;"> 
        <div class="slider-caption-inner-trainer center slider-caption-center"> 
            <h2 data-animate="fadeInUp"></h2> 
        </div>				 
    </section>

    <section id="event-calendar"> 
        <div class="content-wrap nopadding">
            <div class="section notopmargin nobottommargin"> 
                <div class="container clearfix" v-if="store.user">
                    <EventCard/>
                   <FullCalendar :options="calendarOptions"/>
                </div>
            </div>
        </div>
    </section>
    </div>
    
</template>

<script>
import { ref, onMounted,inject, getCurrentInstance } from 'vue';
import axios from 'axios';

import FullCalendar from '@fullcalendar/vue3';
import listPlugin from "@fullcalendar/list";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { store } from '../../store.js';
import EventCard from './components/EventCard.vue';

export default {
    components: {
		FullCalendar,
        EventCard
    },
    data() {
        const app = getCurrentInstance();
        const swal = inject('$swal');
        const events = ref([]);

        async function getEvents() {
            store.loading = true;
            await axios
                .get(process.env.VUE_APP_ENDPOINT+'/events')
                .then(function (res) {
                    store.loading = false;
                    let eventData = res.data.events;
                    for (let x in eventData) {
                        events.value[x] = {
                            className: 'event-list event-'+eventData[x].id,
                            title: eventData[x].name + ' | ' + eventData[x].description,
                            date: eventData[x].start_at,
                            extendedProps: {
                                id: eventData[x].id
                            }
                        };
                    }
                })
                .catch(function (error) {
                    store.loading = false;
                    if (error.response && error.response.status == 401) {
                        window.location.href = '/logout';
                    } else {
                        swal({
                            icon: 'error',
                            text: app.appContext.config.globalProperties.$error500,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    }
                });
        }

        onMounted(() => {
            getEvents();
        });
        
        return {
            store,
            calendarOptions: {
                plugins: [ dayGridPlugin, interactionPlugin, listPlugin],
                initialView: 'dayGridMonth',
                eventClick: this.handleEventClick,
                events: events.value,
                headerToolbar: {
                    start: 'title',
                    center: '',
                    end: 'today prev,next,dayGridMonth,listMonth'
                },
                eventDidMount: function(info) {
                    document.getElementsByClassName(info.event.classNames[1])[0].setAttribute('data-id',info.event.extendedProps.id);
                }
            }
        };
    },
    methods: {
        handleEventClick: function(param) {
            store.eventCardId = param.event.extendedProps.id;
            document.getElementById('eventCardBtn').click();
        },
    }
}
</script>
<style>
.fc .fc-button {
    text-transform: uppercase;
}
.fc-header-toolbar {
    text-transform: uppercase;
}
.fc .fc-daygrid-event {
    background:#3788d8;
    color:#f2eef3 !important;
}
.fc-daygrid-event-dot {
    border-color: #adb5ca !important;
}

a.fc-daygrid-event, .fc-list-event-title a {
    cursor: pointer;
}
@media screen and (max-width:767px) { .fc-toolbar.fc-header-toolbar {font-size: 75%}}
</style>
