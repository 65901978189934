<template>

    <template v-if="loading">
        <div class="card placeholder-glow" style="height:434px" v-for="index in 4" :key="index">
            <div class="placeholder" style="width:100%; height: 128px;"></div>
            <div class="card-body">
                <h4 class="card-title">
                    <div class="placeholder col-12 rounded"></div>
                    <div class="placeholder col-9 rounded"></div>
                </h4>
            </div>
        </div>
    </template >
    
    <div class="row">
        <div class="card" v-for="(event, index) in events" :key="index">
            <img class="card-img-top" :src="event.logo" :alt="event.name"/>
            <div class="card-body">
                <h4 class="card-title">{{ event.name }} | {{ event.description }}</h4>
                <p class="card-text" style="margin-bottom: 0px"></p>
                <div id="cpd_course_type">
                    <span class="text-danger font-weight-bold">{{ event.course_type }}</span>
                </div>
                <br>
            <span v-if="dateFormat(event.start_at) == dateFormat(event.end_at)">
                    <i class="icon-calendar3"></i> {{ dateFormat(event.start_at) }}<br>
                </span>
            <span v-else>
                    <i class="icon-calendar3"></i> {{ dateFormat(event.start_at) }} - {{ dateFormat(event.end_at) }}<br>
                </span>

                <i class="icon-time"></i> {{ timeFormat(event.start_at) }} - {{timeFormat(event.end_at) }}<br>
                <i class="icon-location"></i> <span id="training-location">{{ event.venue }}</span><br>
            </div>
            <div class="card-footer">
                <a :href="event.era_salesperson_link" target="_blank" class="event-link button button-3d button-mini button-rounded button-red button-era-salesperson">ERA Salesperson  <i class="fas fa-external-link-alt"></i></a>
            </div>
            <div class="card-footer">
                <a :href="event.era_pending_salesperson_link" target="_blank" class="event-link button button-3d button-mini button-rounded button-green button-era-salesperson">ERA Pending Salesperson <i class="fas fa-external-link-alt"></i></a>
            </div>
            <div class="card-footer" id="cardfooter371087551857">
                <a :href="event.external_salesperson_link" target="_blank" class="event-link button button-3d button-mini button-rounded button-blue" v-if="event.external_salesperson_link">External Salesperson / RES TO BE<i class="fas fa-external-link-alt"></i></a>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from '../store.js';
import { ref, onMounted,inject, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from "vue-router";
import axios from 'axios';
import moment from 'moment';

export default {
    setup() {
        const app = getCurrentInstance();
        const router = useRouter();
        const route = useRoute();
        const swal = inject('$swal');
        const loading = ref(false);
        const events = ref(false);

        async function getEvents() {
            loading.value = true;
            await axios
                .get(process.env.VUE_APP_ENDPOINT+'/events')
                .then(function (res) {
                    loading.value = false;
                    events.value = res.data.events;
                })
                .catch(function (error) {
                    loading.value = false;
                    if (error.response && error.response.status != 500) {
                        swal({
                            icon: 'error',
                            text: error.response.data.message,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    } else {
                        swal({
                            icon: 'error',
                            text: app.appContext.config.globalProperties.$error500,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    }
                });
        }

        onMounted(() => {
            getEvents();
        });

        return { app, swal, route, router, store, loading, events };
    },
    methods: {
        timeFormat: function (value) {
            if (value) {
                return moment(value).format("hh:mm A");
            }
        },
        dateFormat: function (value) {
            if (value) {
                return moment(value).format("ddd D MMM");
            }
        }
    }
}
</script>
<style scoped>
.training-events .card {
    margin-bottom: 30px;
    padding: 0;
}
</style>