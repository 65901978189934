<template>
    
    <!-- Button trigger modal -->
    <button id="eventCardBtn" type="button" class="btn btn-primary hide" @click="eventCardBtn">
    </button>
    <button ref="eventCardBtnModal" id="eventCardBtnModal" type="button" class="btn btn-primary hide" data-bs-toggle="modal" data-bs-target="#eventCard">
    </button>

    <!-- Modal -->
    <div class="modal fade" id="eventCard"  tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="logo-handler"><img :src="event.logo"/></div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <h4 class="modal-title mb-3" id="exampleModalLabel">{{ event.name }} | {{ event.description }}</h4>
                    <span class="text-danger font-weight-bold" v-if="event.course_type">{{ event.course_type }}</span>
                    <ul class="list-unstyled mt-3">
                        <li>
                            <span v-if="dateFormat(event.start_at) == dateFormat(event.end_at)">
                                <i class="icon-calendar3"></i> {{ dateFormat(event.start_at) }}<br>
                            </span>
                            <span v-else>
                                <i class="icon-calendar3"></i> {{ dateFormat(event.start_at) }} - {{ dateFormat(event.end_at) }}<br>
                            </span>
                        </li>

                        <li><i class="icon-time"></i> {{ timeFormat(event.start_at) }} - {{timeFormat(event.end_at) }}</li>
                        <li><i class="icon-location"></i> {{ event.venue }}</li>
                    </ul>
                </div>
                <div class="modal-footer text-uppercase">
                    <a type="button" class="btn btn-danger btn-sm col-12" :href="event.era_salesperson_link" target="_blank" v-if="event.era_salesperson_link">ERA Salesperson</a>
                    <a type="button" class="btn btn-success btn-sm col-12" :href="event.era_pending_salesperson_link" target="_blank" v-if="event.era_pending_salesperson_link">ERA Pending Salesperson</a>
                    <a type="button" class="btn btn-primary btn-sm col-12" :href="event.external_salesperson_link" target="_blank" v-if="event.external_salesperson_link">External Salesperson / RES TO BE</a>
                </div>
            </div>
        </div>
    </div>


</template>
<script>
import { ref, inject, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from "vue-router";
import axios from 'axios';
import { store } from '../../../store.js';
import moment from 'moment';

export default {
    setup() {
        const app = getCurrentInstance();
        const router = useRouter();
        const route = useRoute();
        const swal = inject('$swal');
        const event = ref([]);

        return { app, router, route, swal, store, event };
    },
    methods: {
        async eventCardBtn() {
            store.loading = true;
            let model = this;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/events/'+store.eventCardId)
            .then(function (res) {
                store.loading = false;
                model.event = res.data.event
                model.$refs.eventCardBtnModal.click();
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status != 500) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        },
        timeFormat: function (value) {
            if (value) {
                return moment(value).format("hh:mm A");
            }
        },
        dateFormat: function (value) {
            if (value) {
                return moment(value).format("ddd D MMM");
            }
        }
    }
}
</script>

<style scoped>
.logo-handler {
    width: 100%;
    text-align: center;
}

#eventCard .btn-close {
    position: absolute;
    top: 5px;
    right: 5px;
}
#eventCardBtn {
    display:none;
}

#eventCard .modal-header {
    padding: 0;
}

#eventCard .modal-dialog {
    max-width: 400px;
}
</style>