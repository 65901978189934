<template>
    <div>
	<section id="page-title" class="page-title-parallax page-title-dark swiper-slide full-screen clearfix3 skrollable skrollable-between" style="padding: 50px 0px; background-image: url('https://erasg.s3.ap-southeast-1.amazonaws.com/uat/245-trainer-listing-bg.jpg'); background-size: cover; background-position: -48.5502px 0px; height: 764px;" data-bottom-top="background-position:-100px 0px;" data-top-bottom="background-position:0px 0px;"> 
		<div class="slider-caption-inner center slider-caption-center"> 
			<h2 data-animate="fadeInUp">Ultimate Trainers</h2> 
			<p class="box-lines-trans" data-animate="fadeInUp" data-delay="300">The Ultimate Agent Training provides you with real estate solutions to real life day to day issues you encounter as real estate professionals. A proven system to propel you from where you are, to where you want to be. Create 100 to 1000% profit growth in business and achieve true financial freedom.</p> 
			<a href="#" data-scrollto="#content" data-offset="100" class="dark one-page-arrow"><i class="icon-angle-down infinite animated fadeInDown"></i></a> 
		</div>				 
	</section>
            
	<section id="content" style="margin-bottom: 0px;"> 
				<div class="content-wrap nopadding"> 
					<!--Our Trainers Start ============================================= -->					 
					<div class="section notopmargin nobottommargin notopborder center"> 
						<div class="container clearfix"> 
							<div class="heading-block topmargin-sm"> 
								<h1>Meet Our Trainers</h1> 
							</div>							 
							<TrainerRank :rank="rank" v-for="(rank, index) in ranks" :key="index"/>
						</div>						 
					</div>					 
					<!--Our Trainers End ============================================= -->					 
					<!--icons start ============================================= -->					 
				</div>				 
			</section>
    </div>	
</template>

<script>
import axios from 'axios';
import { ref, onMounted, inject, getCurrentInstance } from 'vue';
import { useRouter } from "vue-router";
import { store } from '../store.js';
import TrainerRank from '../components/TrainerRank.vue';

export default {
    components: {
		TrainerRank
    },
    setup() {
        const app = getCurrentInstance();
		const router = useRouter();
		const swal = inject('$swal');
		const ranks = ref(false);

		async function getRanks() {
                store.loading = true;
                await axios
                .get(process.env.VUE_APP_ENDPOINT+'/trainers')
                .then(function (res) {
                    store.loading = false;
                    ranks.value = res.data.ranks;
                })
                .catch(function (error) {
                    store.loading = false;
                    if (error.response && error.response.status == 404) {
                        //redirect to 404 page
                    } else {
                        swal({
                            icon: 'error',
                            text: app.appContext.config.globalProperties.$error500,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    }
                });
            }
            
            onMounted(() => {
                let metaDescription = 'The Ultimate Agent Training provides you with real estate solutions to real life day to day issues you encounter as real estate professionals. A proven system to propel you from where you are, to where you want to be. Create 100 to 1000% profit growth in business and achieve true financial freedom.';
                document.querySelector('meta[name="description"]').setAttribute("content",metaDescription);
                getRanks();
            });

            return { router, swal, ranks };

    },
    methods: {
        
    }
}
</script>
