<template>
    <div class="section dark notopmargin nobottommargin noborder" style="background-image: url('https://erasg.s3.ap-southeast-1.amazonaws.com/uat/241-join-us-bg.jpg'); background-repeat: no-repeat; background-size: auto; background-color: #8c0107;"> 
        <div class="container clearfix"> 
            <div class="heading-block center divcenter nobottommargin nobottomborder"> 
                <h1 class="box-lines-trans-full divcenter">Interested in joining ERA Singapore?</h1> 
                <a href="https://ijoinera.era.com.sg" target="_blank" class="button button-border button-light button-circle button-xlarge topmargin-sm noleftmargin">Join Us</a> 
            </div>						 
        </div>					 
    </div>
</template>
<script>
export default {
}
</script>