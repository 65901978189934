import { createRouter, createWebHistory } from 'vue-router';
import NotFound from '../views/NotFound.vue';
import TrainerList from '../views/TrainerList.vue';
import CoursePage from '../views/CoursePage.vue';
import TrainerPage from '../views/TrainerPage.vue';
import HomePage from '../views/HomePage.vue';
import RoadMap from '../views/RoadMap/RoadMap.vue';
import Calendar from '../views/Calendar/CalendarIndex.vue';
import ContactUs from '../views/Contact/ContactUs.vue';
import LoginPage from '../views/LoginPage.vue';
import LogoutPage from '../views/LogoutPage.vue';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/contact-us',
            name: 'ContactUs',
            component: ContactUs,
            meta: {
                title: 'Contact Us'
            }
        },
        {
            path: '/calendar',
            name: 'Calendar',
            component: Calendar,
            meta: {
                title: 'Calendar Events'
            }
        },
        {
            path: '/roadmap',
            name: 'RoadMap',
            component: RoadMap,
            meta: {
                title: 'Road Map'
            }
        },
        {
            path: '/404',
            name: 'NotFound',
            component: NotFound,
            meta: {
                title: '404 Not Found'
            }
        },
        {
            path: '/trainers/:slug',
            name: 'TrainerPage',
            component: TrainerPage,
            meta: {
                title: 'Trainer'
            }
        },
        {
            path: '/trainers',
            name: 'TrainerList',
            component: TrainerList,
            meta: {
                title: 'Trainers'
            }
        },
        {
            path: '/:slug',
            name: 'CoursePage',
            component: CoursePage,
            meta: {
                title: 'Course'
            }
        },
        {
            path: '/',
            name: 'HomePage',
            component: HomePage
        },
        {
            path: '/login',
            name: 'LoginPage',
            component: LoginPage
        },
        {
            path: '/logout',
            name: 'LogoutPage',
            component: LogoutPage
        },
    ]
})

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title+' | '+ process.env.VUE_APP_NAME;
    } else {
        document.title = process.env.VUE_APP_NAME;
    }
	next();
});

export default router