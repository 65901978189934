<template>
    <div class="container clearfix">
        <div class="col_one_third nobottommargin topmargin center clearfix"> 
            <img data-animate="fadeIn" :src="trainer.portrait" :alt="trainer.name+' - '+ trainer.rank" class="fadeIn animated" style="width: 320px; height: 480px;">
            <div class="si-share clearfix"> 
                <span>Share:</span> 
                <div> 
                    <a :href="trainer.social.facebook" class="social-icon si-borderless si-facebook" target="_blank"> <i class="icon-facebook" v-if="trainer.social.facebook"></i> <i class="icon-facebook"></i> </a>

                    <a :href="trainer.social.instagram" class="social-icon si-borderless si-instagram" target="_blank"> <i class="icon-instagram" v-if="trainer.social.instagram"></i> <i class="icon-instagram"></i>
                    </a>
                    <a target="_blank" :href="trainer.social.whatsapp" class="social-icon si-borderless si-whatsapp" v-if="trainer.social.whatsapp"> <i class="icon-whatsapp"></i> <i class="icon-whatsapp"></i></a>
                </div>								 
            </div>							 
            <div class="row topmargin-sm hidden">
                <a href="#" target="_blank" class="button button-large button-red button-circle divcenter">More About Trainer</a> 
            </div>							 						 
        </div>
        <div class="col_two_third col_last"> 
            <div class="heading-block topmargin"> 
                <h2>{{ trainer.name }}</h2> 
            </div>							 
            <h4>{{ trainer.rank }}</h4> 
            <p>CEA License: {{ trainer.cea_regno }}</p>
            <p v-html="trainer.description"></p>
        </div>
    </div>
</template>
<script>
export default {
    props: ['trainer']
}
</script>