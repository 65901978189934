<template>
    <div v-if="store.user">
        <div class="section dark notopmargin nobottommargin noborder" style="background-image: url('https://erasg.s3.ap-southeast-1.amazonaws.com/uat/244-rsvp-bg.jpg'); background-repeat: no-repeat; background-size: cover;"> 
            <div class="container clearfix"> 
                <div class="heading-block center nobottommargin nobottomborder"> 
                    <h2>RSVP Now!</h2> 
                    <span>ERA is the Agency of Choice for both agents and consumers. Through our cutting edge technology and constantly evolving Ultimate Agent Training, we have maintained our position as a forerunner of the real estate industry, in service excellence, professional ethics, and client satisfaction.</span> 
                    <br/> 
                    <div class="center"> 
                        <a href="/#upcoming-event" class="button button-border button-light button-circle noleftmargin topmargin-sm">Register Here</a> 
                    </div>								 
                </div>							 
            </div>						 
        </div>
    </div>
</template>
<script>
</script>
<script>
import { store } from '@/store.js';

export default {
    setup() {
        return { store }
    }
}
</script>