<template>
    <ul v-if="menus">
        <li v-for="(menu, index) in menus" :key="index" :class="{ 'sub-menu': menu.subs }">
            <template v-if="!store.user && (menu.link == '/trainers' || menu.link == '/calendar')">
            </template>
            <template v-else>
                <a :href="menu.link" :target="menu.target"><div v-html="menu.name"></div></a> 
            </template>
            <MenuItems :menus="menu.subs"/>
        </li>
    </ul>
</template>
<script>
import { store } from '@/store.js';
import MenuItems from './MenuItems.vue';

export default {
    props: ['menus'],
    components: {
        MenuItems
    },
    setup() {
        return  { store } 
    }
}
</script>