<template>
    <div class="section notopmargin nobottommargin notopborder center"> 
        <div class="container clearfix"> 
            <div class="heading-block"> 
                <h1>All Training By {{ trainer.name }}</h1> 
                <h4>Experience Results and Archieve Your Goals With UAT Proven Real Estate Training Solutions</h4> 
            </div>							 
            <div class="container clearfix"> 
                <div class="justify-content-center row"> 
                    <div class="col-lg-4 col-md-6 col-sm-6" v-for="(course, index) in trainer.courses" :key="index"> 
                        <div class="logo-list ipost clearfix"> 
                            <div class="entry-image"> 
                                <a :href="course.url" :title="course.title" v-if="course.logo">
                                <img :alt="course.title" class="image_fade col-padding-sm" :src="course.logo" style="opacity: 1; width: 330px; height: 138px;"></a> 
                                <a :href="course.url" :title="course.title" v-else>
                                    <h3>{{ course.title }}</h3>
                                </a>
                            </div>											 
                        </div>										 
                    </div>
                </div>								 
            </div>							 
        </div>						 
    </div>
</template>
<script>
export default {
    props: ['trainer'],
}
</script>
<style scoped>
.logo-list img{
    background:#A9A9A9;
}
</style>