<template>
    <div>
        <section style="text-align: center;">
            <img :src="post.cover" :alt="post.title">
        </section>

        <section id="content"> 
                <div class="content-wrap nopadding"> 
                    <div class="row col-padding clear-bottommargin center clearfix"> 
                        <div class="heading-block col_three_fourth divcenter nobottomborder"> 
                            <div v-html="post.content"></div>
                        </div>
                    </div>
                </div>
        </section>

        <JoinUs/>
    </div>

</template>
<script>
import JoinUs from '../../components/JoinUs.vue';
import axios from 'axios';
import { ref, onMounted, inject, getCurrentInstance } from 'vue';
import { store } from '../../store.js';

export default {
    components: {
		JoinUs
    },
    setup() {
        const app = getCurrentInstance();
        const swal = inject('$swal');
        const post = ref(false);

        async function getPost() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/post/roadmap')
            .then(function (res) {
                store.loading = false;
                post.value = res.data.post;
                document.title = res.data.post.title + ' | ' + process.env.VUE_APP_NAME;
                //document.querySelector('meta[name="description"]').setAttribute("content",res.data.post.meta.description);
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 404) {
                   window.location.href = '/404';
                } else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }

		onMounted(() => {
            getPost();
        });

		return { post }
    },
}
</script>
<style scoped>
.table th, .table td {
    border:none;
}

tbody, td, tfoot, th, thead, tr {
    border-top: solid 1px rgba(255,255,255,0.1);
}
</style>