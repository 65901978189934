<template>
    <div>
        <header id="header" class="transparent-header semi-transparent dark full-header" data-sticky-class="dark">
        <div id="header-wrap"> 
            <div class="container clearfix"> 
                <div id="primary-menu-trigger"> 
                    <i class="icon-reorder"></i> 
                </div>						 
                <!-- Logo
            ============================================= -->						 
                <div id="logo"> 
                    <a href="/" class="standard-logo" data-dark-logo="images/logo-dark.png"><img src="https://erasg.s3.ap-southeast-1.amazonaws.com/uat/246-logo-dark.png" alt="Ultimate Agent Training Logo"></a> 
                    <a href="/" class="retina-logo" data-dark-logo="images/logo-dark@2x.png"><img src="https://erasg.s3.ap-southeast-1.amazonaws.com/uat/247-logo-retina.png" alt="Ultimate Agent Training"></a> 
                </div>						 
                <!-- #logo end -->

                <nav id="primary-menu" class="dark">
                    <ul>
                        <li>
                            <a href="/"><br><i class="icon-home2"></i></a>
                        </li>
                    </ul>
                    <MenuItems :menus="menus"/>
                    <ul>
                        <li v-if="!store.user">
                            <a href="/login"><br/>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                                    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                                </svg>
                                &nbsp;Login</a>
                        </li>
                        <li v-else>
                            <a href="/logout"><br/>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                                    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                                </svg>
                                &nbsp;Logout</a>
                        </li>
                    </ul>
                </nav>
            </div>					 
        </div>
        </header>
            
            <router-view></router-view>

        <footer id="footer" class="dark"> 
            <div class="container"> 
                <!-- Footer Widgets
            ============================================= -->					 
                <div class="footer-widgets-wrap clearfix"> 
                    <div class="col_full"> 
                        <div class="col_one_fourth"> 
                            <div class="widget clearfix"> 
                                <img src="@/assets/images/UAT-logo-white.png" style="width: 133px; height: 58px;" alt="UAT Logo" class="footer-logo"> 
                                <div> 
                                    <address> <strong>Headquarters:</strong><br> 
                                    450 Lor 6 Toa Payoh<br> 
                                    Singapore 319394<br></address> 
                                    <strong>Phone:</strong> (65) 6226 2000
                                    <br> 
                                    <strong>Email:</strong> 
                                    <a href="mailto:era@era.com.sg">era@era.com.sg</a> 
                                </div>									 
                            </div>								 
                        </div>							 
                        <div class="col_one_fourth"> 
                            <div class="widget widget_links clearfix"> 
                                <h4>Official Websites</h4> 
                                <ul> 
                                    <li> 
                                        <a href="https://www.era.com.sg/era-asia-pacific/" target="_blank">ERA Asia Pacific</a> 
                                    </li>										 
                                    <li> 
                                        <a href="https://www.era.com.sg/" target="_blank">ERA Realty Network</a> 
                                    </li>										 
                                    <li> 
                                        <a href="https://my.era.com.sg" target="_blank">myERA</a> 
                                    </li>										 
                                </ul>									 
                            </div>								 
                        </div>							 
                    </div>						 
                    <div class="col_one_fourth col_last"> 
                        <div class="widget clearfix" style="margin-bottom:-20px;"> 
                            <h4>Connect With Us</h4> 
                            <div class="fleft clearfix"> 
                                <a href="https://www.facebook.com/ERASG" target="_blank" class="social-icon si-borderless si-facebook"> <i class="icon-facebook"></i> <i class="icon-facebook"></i></a> 
                                <a href="https://www.youtube.com/user/ERARealtyNetwork" target="_blank" class="social-icon si-borderless si-youtube"> <i class="icon-youtube"></i> <i class="icon-youtube"></i></a> 
                                <a href="https://www.instagram.com/erasgofficial/" target="_blank" class="social-icon si-borderless si-instagram"> <i class="icon-instagram"></i> <i class="icon-instagram"></i></a> 
                                <a href="https://www.linkedin.com/company/era" target="_blank" class="social-icon  si-borderless si-linkedin"> <i class="icon-linkedin"></i> <i class="icon-linkedin"></i></a> 
                            </div>								 
                        </div>							 
                    </div>						 
                </div>					 
                <!-- footer-widgets-wrap end -->					 
            </div>			 
            <!-- Copyrights ============================================= -->				 
            <div id="copyrights"> 
                <div class="container clearfix"> 
                    Copyright&copy; 2020 ERA Realty Network Pte Ltd. (CEA Licence No. L3002382K). All Rights Reserved. | 
                    <a href="https://www.era.com.sg/privacy-policy/" target="_blank">Privacy Policy</a> 
                    <div class="clear"></div>						 
                </div>					 
            </div>				 
            <!-- copyrights end ============================================= -->				 
        </footer>

            <div class="css3-spinner" v-if="store.loading">
                <div class="css3-spinner-bounce1"></div>
                <div class="css3-spinner-bounce2"></div>
                <div class="css3-spinner-bounce3"></div>
            </div>

    </div>
</template>

<script>
import { ref, onMounted, inject, getCurrentInstance } from 'vue';
import { useRouter } from "vue-router";
import axios from 'axios';
import { store } from './store.js';
import MenuItems from './components/MenuItems.vue';

export default {
  components: {
    MenuItems
  },
  setup() {
     const app = getCurrentInstance();
    const router = useRouter();
    const swal = inject('$swal');
    const menus = ref(false);

    async function init() {
    let authtoken = localStorage.getItem("authToken");
    let optionalHeaders = {};
    if (authtoken) {
        optionalHeaders = {
            'Authorization': 'Bearer '+ authtoken
        };
    }

    axios.interceptors.request.use(
        function (config) {
            if (optionalHeaders) {
                config.headers = {
                    ...config.headers,
                    ...optionalHeaders
            };
            }
            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );

      axios.interceptors.response.use(function (response) {
        return response;
      }, function (error) {
        return Promise.reject(error);
      });

    }

    async function getInfo() {
        await axios
            .get(process.env.VUE_APP_ENDPOINT+'/auth/info')
            .then(function (res) {
                store.user = res.data.user;
            })
            .catch(function (error) {
                if (error.response && error.response.status == 401) {
                    localStorage.removeItem('authToken');
                    window.location.href = '/';
                } else if (error.response && error.response.status != 500) {
                    swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }

    async function getMenus() {
        await axios
            .get(process.env.VUE_APP_ENDPOINT+'/menus',{
                params: {
                    location: 'PRIMARY'
                }
            })
            .then(function (res) {
                menus.value = res.data.menus;
            })
            .catch(function (error) {
                if (error.response && error.response.status == 401) {
                    router.push({ name: 'Logout' });
                } else if (error.response && error.response.status != 500) {
                    swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }
    
    onMounted(() => {
      init();
      getMenus();
      
      let authtoken = localStorage.getItem("authToken");
      if (authtoken) {
        getInfo();
      }
    });

    return  { store, menus } 
  }
}
</script>