<template>
	<div>
    <section id="slider" class="slider-element slider-parallax swiper_wrapper full-screen force-full-screen clearfix"> 
		<div class="vh-100 slider-parallax-inner"> 
			<div class="swiper-container swiper-parent"> 
				<div class="swiper-wrapper"> 
					<div class="swiper-slide dark" 
					style="padding: 50px 0; background-size: cover;"
					:style="'background-image: url(' + post.cover + ');'"> 
						<div class="container clearfix"> 
							<div class="slider-caption slider-caption-center"> 
								<p></p> 
								<h2 data-animate="fadeIn" data-delay="200">{{ post.title }}</h2> 
								<p class="box-lines-trans nomax-width divcenter" data-animate="fadeInUp" data-delay="400">{{ post.description }}</p> 
							</div>									 
						</div>								 
					</div>							 
				</div>						 
			</div>					 
			<a href="#" data-scrollto="#content" data-offset="100" class="dark one-page-arrow"><i class="icon-angle-down infinite animated fadeInDown"></i></a> 
		</div>				 
	</section>


            <section id="content"> 
				<div class="content-wrap nopadding"> 
					<!--Upcoming events start ============================================= -->					 
					<div id="upcoming-event" class="section notopmargin nobottommargin" v-if="store.user"> 
						<div class="container clearfix"> 
							<div class="heading-block nobottomborder center"> 
								<h1>Upcoming Events</h1> 
								<h4>FOR AGENTS, BY TOP LEADERS. FOR RESULTS, THROUGH PASSION</h4> 
							</div>

							<div class="row">
								<div class="col-md-12 col-lg-6">
									<h3>Ultimate Agent Trainings</h3>
								</div>
								<div class="col-md-12 col-lg-6 text-right calendar-event">
									<h3><a href="/calendar"><i class="fa-solid fa-calendar-days"></i> Full Calendar View</a></h3>
								</div>
							</div>

							<div id="events">
								<div class="card-deck training-events">

                                    <EventCards v-if="post"/>

								</div>
							</div>
							<div id="oc-posts" class="owl-carousel posts-carousel carousel-widget" data-autoscroll="true" data-autoplay="5000" data-margin="20" data-nav="false" data-pagi="true">  								 
							</div>							 
						</div>						 
					</div>					 
					<!--Upcoming Events end ============================================= -->					 

					<div class="container clearfix"> 
						<div class="heading-block divcenter center light col_four_fifth topmargin nobottomborder"> 
							<div v-html="post.content"></div>
						</div>
						<div class="hr-hor-gra nobottommargin"></div>						 
						<div class="heading-block topmargin bottommargin-lg nobottomborder center"> 
							<h1>Welcome To UAT</h1> 
							<p>The Ultimate Agent Training is a series of courses specially designed to elevate the results and performance of our real estate professionals. Through the process of unlearning and relearning, the UAT transforms all ERA agents into consummate Realtorpreneurs.</p> 
							<a href="roadmap" class="button button-xlarge button-circle button-red noleftmargin">View UAT Road Map</a> 
							<!--<a href="trainer-listing" class="button button-xlarge button-circle button-red noleftmargin">Meet The Trainers</a>-->							 
						</div>						 
					</div>					 
					
				</div>				 
				<!--join us start ============================================= -->				 
				<JoinUs/>
			</section>
	</div>
</template>

<script>
import EventCards from '../components/EventCards.vue';
import JoinUs from '../components/JoinUs.vue';
import axios from 'axios';
import { ref, onMounted, inject, getCurrentInstance } from 'vue';
import { store } from '@/store.js';

export default {
    components: {
        EventCards,
		JoinUs
    },
    setup() {
		const app = getCurrentInstance();
        const swal = inject('$swal');
        const post = ref(false);

		async function getPost() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/post/homepage')
            .then(function (res) {
                store.loading = false;
                post.value = res.data.post;
                document.title = res.data.post.title + ' | ' + process.env.VUE_APP_NAME;
               // document.querySelector('meta[name="description"]').setAttribute("content",res.data.post.meta.description);
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 404) {
                   window.location.href = '/404';
                } else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }

		onMounted(() => {
            getPost();
        });

		return { post, store }
    },
    methods: {
        
    }
}
</script>
<style scoped>
#slider h2 {
	white-space: normal;
}
.calendar-event {
	padding-right: 35px;
}
.calendar-event a{
	color:#515151;
}
</style>