<template>
	
    <section id="page-title" class="page-title-parallax page-title-dark swiper-slide full-screen clearfix3 skrollable skrollable-between" style="padding: 50px 0px; background-image: url('https://d2u1upwdbw709w.cloudfront.net/uat/282-404.jpg'); background-size: cover; background-position: -48.5502px 0px; height: 764px;" data-bottom-top="background-position:-100px 0px;" data-top-bottom="background-position:0px 0px;"> 
		<div class="slider-caption-inner center slider-caption-center"> 
			<h2 data-animate="fadeInUp">404 <br> Page Not Found</h2> 
		</div>				 
	</section>

</template>

<script>

export default {
    components: {
    },
    setup() {
        
    },
    methods: {
        
    }
}
</script>
